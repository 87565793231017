<template>
    <div class="card h-100 mb-4">
        <div class="card-header pb-0 px-3">
            <div class="row">
                <div class="col-md-6">
                    <h6 class="mb-0">Available Batches</h6>
                </div>
                <div class="col-md-6 d-flex justify-content-end align-items-center">
                    <button type="button" class="btn btn-link text-primary text-gradient px-3 mb-0 fw-normal"
                        data-bs-toggle="modal" data-bs-target="#createBatchModal">Create Batch
                    </button>

                    <!-- Modal -->
                    <div class="modal fade" id="createBatchModal" tabindex="-1" aria-labelledby="deleteModalLabel"
                        aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered modal-lg">
                            <!-- FORM HERE -->
                            <form @submit.prevent="saveBatch" class="modal-content">
                                <div class="modal-header px-4">
                                    <h1 class="modal-title fs-5 text-black" id="deleteModalLabel">Create Batch</h1>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal"
                                        aria-label="Close"></button>
                                </div>
                                <div class="modal-body text-left">
                                    <div class="row m-0 p-0">
                                        <div class="col-12 form-group">
                                            <label for="">Batch Name</label>
                                            <input type="text" v-model="create_batch.name" class="form-control rounded-0"
                                                placeholder="Batch Name">
                                        </div>
                                        <div class="col-3 form-group">
                                            <label for="">Batch Colour (Optional)</label>
                                            <input type="color" v-model="create_batch.info"
                                                class="form-control rounded-0 py-4" placeholder="Batch Name">
                                        </div>
                                        <div class="col-3 form-group d-flex align-items-end">
                                            <div class="p-4" :style="'background-color: ' + create_batch.info"></div>
                                            <!-- <label for="">Batch Name</label> -->
                                            <!-- <input type="color" class="form-control rounded-0" placeholder="Batch Name"> -->
                                        </div>
                                        <div class="col-6 form-group d-flex align-items-end">
                                            <label for="">Batch Status</label>
                                            <select v-model="create_batch.status" name="" id="" class="form-control">
                                                <option value="active">Active</option>
                                                <option value="completed">Completed</option>
                                            </select>
                                            <!-- <input type="color" class="form-control rounded-0" placeholder="Batch Name"> -->
                                        </div>
                                        <!-- <h5>Create Batch</h5> -->

                                    </div>
                                    <!-- <h6 class="fs-2 text-left">{{ student.firstname }}</h6> -->
                                </div>
                                <div class="modal-footer">
                                    <button type="button" id="closeBatchCreationModal" class="btn btn-dark"
                                        data-bs-dismiss="modal">Close</button>
                                    <button type="submit" class="btn btn-danger">Save
                                    </button>
                                </div>
                            </form>
                            <!-- FORM END HERE -->
                        </div>
                    </div>
                    <!-- <i class="far fa-calendar-alt me-2" aria-hidden="true"></i> -->
                    <!-- <small>23 - 30 March 2020</small> -->
                </div>
            </div>
        </div>
        <div class="card-body pt-4 p-3">
            <h6 class="text-uppercase text-body text-xs font-weight-bolder mb-3">Active</h6>
            <ul class="list-group">
                <div v-for="batch in batches" :key="batch.id">

                    <li v-if="batch.status == 'active'"
                        class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
                        <div class="d-flex align-items-center">
                            <argon-button color="black" :style="'color: ' + batch.info" variant="outline" size="sm"
                                class="btn-icon-only btn-rounded mb-0 me-3 d-flex align-items-center justify-content-center">
                                <i class="fas fa-circle" aria-hidden="true"></i>
                            </argon-button>
                            <div class="d-flex flex-column">
                                <h6 class="mb-1 text-dark text-sm">{{ batch.name }}</h6>
                                <span class="text-xs">{{ parseDatetime(batch.created_at)}}</span>
                            </div>
                        </div>
                        <div class="d-flex align-items-center text-sm font-weight-bold"
                            :style="'color: ' + batch.info"> {{ batch.students_count ?? 0 }} Students</div>
                    </li>
                </div>

            </ul>
            <h6 class="text-uppercase text-body text-xs font-weight-bolder my-3">Completed</h6>
            <ul class="list-group">
                <div v-for="batch in batches" :key="batch.id">

                    <li v-if="batch.status != 'active'"
                        class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
                        <div class="d-flex align-items-center">
                            <argon-button color="black" :style="'color: ' + batch.info" variant="outline" size="sm"
                                class="btn-icon-only btn-rounded mb-0 me-3 d-flex align-items-center justify-content-center">
                                <i class="fas fa-circle" aria-hidden="true"></i>
                            </argon-button>
                            <div class="d-flex flex-column">
                                <h6 class="mb-1 text-dark text-sm">{{ batch.name }}</h6>
                                <span class="text-xs">{{ parseDatetime(batch.created_at)}}</span>
                            </div>
                        </div>
                        <div class="d-flex align-items-center text-sm font-weight-bold"
                            :style="'color: ' + batch.info"> {{ batch.students_count ?? 0 }} Students</div>
                    </li>
                </div>
            </ul>
        </div>
    </div>
</template>
  
<script>
import ArgonButton from "@/components/ArgonButton.vue";
import axiosConfig from "../../../baseaxios";

export default {
    name: "list-batches",
    components: {
        ArgonButton,
    },
    data() {
        return {
            batches: [],
            create_batch: {
                name: "",
                info: "",
                status : "active",
            }
        }
    },
    mounted() {
        axiosConfig.get('/back/batches/list').then(resp => {
            this.batches = resp.data;
            console.log(resp);
        })
    },
    methods: {
        saveBatch() {
            axiosConfig.post('/back/batches/save', this.create_batch).then(resp => {
                console.log(resp);
                this.batches = resp.data;
                document.getElementById(`closeBatchCreationModal`).click();
            })
            // alert(1);
        },
        parseDatetime(datetime) {
            const parsedDate = new Date(datetime);

            // Format the date as you need
            const options = {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                // hour: 'numeric',
                // minute: 'numeric',
                // second: 'numeric',
                hour12: false
            };

            return parsedDate.toLocaleString('en-US', options);
        }
    }
};
</script>
  
<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="row mt-4">
          <div class="col-xl-9 mb-xl-0 mb-4">
            <div class="row">
              <div class="col-md-4">
                <wallet-card classIcon="text-white fas fa-credit-card" title="To Collect" desc="Amount to be collected"
                  :price="formatNumber(total_fee)" bg="#FF4444" />
              </div>
              <div class="col-md-4">
                <wallet-card classIcon="text-white fas fa-credit-card bg-primary" title="Collected" desc="Total Collection from students "
                  :price="formatNumber(total_fee - total_collected_amount)" bg="blue"/>
              </div>
              <div class="col-md-4">
                <wallet-card :classIcon="salary.classIcon" :title="salary.title" :desc="salary.desc"
                  :price="salary.price" bg="blue" />
              </div>
            </div>
            <!-- <master-card /> -->
            <!-- <div class="card p-5">
              <router-link to="/students/collect-fees">Collect Fee</router-link>
            </div> -->
          </div>
          <div class="col-xl-3">
            <div class="row">

              <div class="col-md-12">
                <wallet-card bg="red" :classIcon="paypal.classIcon" :title="paypal.title" :desc="paypal.desc" :price="formatNumber(total_fee)" />
              </div>
            </div>
          </div>
          <div class="col-md-12 mb-4">
            <!-- <payment-card /> -->
          </div>
        </div>
      </div>
      <!-- <div class="col-lg-4">
          <invoice-card class="mt-4" />
        </div> -->
    </div>
    <div class="row">
      <div class="col-md-7">
        <course-list></course-list>
      </div>
      <div class="col-md-5">
        <list-batches></list-batches>
      </div>
    </div>
  </div>
</template>
  
<script>
import axiosConfig from "../../baseaxios";
import WalletCard from "../components/classroom/wallet-card.vue";
//   import PaymentCard from "../components/PaymentCard.vue";
//   import InvoiceCard from "../components/InvoiceCard.vue";
import CourseList from "../components/classroom/course-list.vue";
import ListBatches from "../components/classroom/list-batches.vue";

export default {
  name: "Billing",
  components: {
    WalletCard,
    //   PaymentCard,
    //   InvoiceCard,
    CourseList,
    ListBatches,
  },
  data() {
    return {
      students: [],
      total_collected_amount: 0,
      total_fee: 0,
      salary: {
        classIcon: "text-white fas fa-credit-card",
        title: "Salary",
        desc: "Belong Interactive",
        price: "+$2000",
      },
      paypal: {
        classIcon: "text-white fas fa-wallet",
        title: "Overall Turnover",
        desc: "Estimated fees to be collected",
        price: "$455.00",
      },
    };
  },

  mounted() {
    var limit = 'all';
    var filter = 'all';
    axiosConfig.get(`back/students/list/${limit}/${filter}`).then(resp => {
      this.students = resp.data.students;
      for (let i = 0; i < this.students.length; i++) {
        const stt = this.students[i];
        for (let j = 0; j < stt.courses.length; j++) {
          const cours = stt.courses[j];
          this.total_fee = this.total_fee + parseInt(cours.fee_sp);

          console.log(cours.fee_sp);

          // Parse the numeric value and format it

        }
        // FOR CALCULATING COLLECTED FEES
        if (stt.feestatus) {
          this.total_collected_amount = this.total_collected_amount + parseInt(stt.feestatus.amount);
          // console.log("Fee for", stt.feestatus.amount)
        }
        // DONE
        // this.total_fee = parseFloat(this.total_fee) || 0;
        // this.total_fee = `${this.total_fee.toFixed(2)}`;
        console.log(stt);

      }
      console.log(resp)
    })
  },
  methods: {
    formatNumber(value) {
      // Use toLocaleString() to format the number with commas
      return value.toLocaleString('en-US');
    }
  },

};
</script>
  
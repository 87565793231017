<template>
    <div>
        <!-- <a class="btn btn-link text-danger text-gradient px-3 mb-0" href="javascript:;">
            <i class="far fa-trash-alt me-2" aria-hidden="true"></i>Delete
        </a> -->

        <button type="button" class="btn btn-link text-danger text-gradient px-3 mb-0" data-bs-toggle="modal"
            :data-bs-target="'#deleteModal' + course.id">
            <i class="far fa-trash-alt me-2" aria-hidden="true"></i>Delete
        </button>

        <!-- Modal -->
        <div class="modal fade" :id="'deleteModal' + course.id" tabindex="-1" aria-labelledby="deleteModalLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <!-- FORM HERE -->
                <form @submit.prevent="" class="modal-content">
                    <div class="modal-header px-4">
                        <h1 class="modal-title fs-5" id="deleteModalLabel">Sure Want to Delete?</h1>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <h6 class="text-center fs-4">Are you sure wanted to delete?</h6>
                        <h6 class="text-center fs-2">{{ course.name }}</h6>
                        <h6 class="text-center fs-6 fw-light">Remember! You can't undo this</h6>
                    </div>
                    <div class="modal-footer">
                        <button type="button" :id="'closeDeleteModal' + course.id" class="btn btn-dark" data-bs-dismiss="modal">Close</button>
                        <button type="submit" @click="deleteCourse()" class="btn btn-danger">{{ isloading ? 'Deleting' : 'Delete Now' }}
                        </button>
                    </div>
                </form>
                <!-- FORM END HERE -->
            </div>
        </div>
    </div>
</template>
<script>
import axiosConfig from "../../../baseaxios"
export default {
    props: ['course', 'grabCourses'],
    data() {
        return {
            isloading: false
        }
    },
    methods: {
        // delete
        deleteCourse() {
            
            // $(`#deleteModal${this.course.id}`).modal('toggle')
            axiosConfig.post(`/back/courses/delete/${this.course.id}`).then(resp => {
                document.getElementById(`closeDeleteModal${this.course.id}`).click();
                console.log(resp.data);
                this.grabCourses();
            })
        }
    }
}
</script>
<template>
    <div class="card">
        <div class="card-header pb-0 px-3 d-flex align-items-center  justify-content-between mx-2">
            <h6 class="mb-0 text-black fs-5 fw-bolder">Courses Information</h6>
            <!-- Button trigger modal -->
            <button type="button" class="btn btn-dark d-flex align-items-center justify-content-between"
                style="background-color: black;" data-bs-toggle="modal" data-bs-target="#exampleModal">
                <i class="fas fa-plus me-2"></i>
                Add Course
            </button>

            <!-- Modal -->
            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-lg">
                    <!-- FORM HERE -->
                    <form @submit.prevent="saveCourse()" class="modal-content">
                        <div class="modal-header px-4">
                            <h1 class="modal-title fs-5" id="exampleModalLabel">Add New Course</h1>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="col-12 row m-0 p-0">
                                <div class="col-6 form-group p-0 mb-2 px-2">
                                    <label for="course_name" class="fw-semibolder">Course Name</label>
                                    <input type="text" class="form-control rounded-0" v-model="create_course.name"
                                        placeholder="eg: Fundamental of Information Technology">
                                </div>
                                <div class="col-6 form-group p-0 mb-2 px-2">
                                    <label for="course_name" class="fw-semibolder">Course Info</label>
                                    <input type="text" class="form-control rounded-0" v-model="create_course.info"
                                        placeholder="eg: Basic info about this course">
                                </div>
                                <div class="col-6 form-group p-0 mb-2 px-2">
                                    <label for="course_name" class="fw-semibolder">Course Period</label>
                                    <input type="text" class="form-control rounded-0" v-model="create_course.period"
                                        placeholder="eg: 180 Days">
                                </div>
                                <div class="col-6 form-group p-0 mb-2 px-2">
                                    <label for="course_name" class="fw-semibolder">Course Type</label>
                                    <select v-model="create_course.type" id="" class="form-control rounded-0">
                                        <option value="">Select Course type</option>
                                        <option value="physical">Physical</option>
                                        <option value="online">Online</option>
                                    </select>
                                    <!-- <input type="text" class="form-control rounded-0"> -->
                                </div>
                                <div class="col-6 form-group p-0 mb-2 px-2">
                                    <label for="course_name" class="fw-semibolder">Fee (Marked Price)</label>
                                    <input type="number" class="form-control rounded-0" v-model="create_course.fee_mp"
                                        placeholder="eg: 12000">
                                </div>
                                <div class="col-6 form-group p-0 mb-2 px-2">
                                    <label for="course_name" class="fw-semibolder">Fee (Sale Price)</label>
                                    <input type="number" class="form-control rounded-0" v-model="create_course.fee_sp"
                                        placeholder="eg: 10000">
                                </div>

                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="submit" class="btn btn-primary">{{ isloading ? 'Saving' : 'Save changes' }}
                            </button>
                        </div>
                    </form>
                    <!-- FORM END HERE -->
                </div>
            </div>
        </div>
        <div class="card-body pt-4 p-3">
            <ul class="list-group">
                <li v-for="course in courses" :key="course.id"
                    class="list-group-item border-0 d-flex p-4 mb-2 bg-gray-100 border-radius-lg">
                    <div class="d-flex flex-column">
                        <h6 class="mb-1 text-md fw-bolder fs-6 ">{{ course.name }}</h6>
                        <span class="mb-2 text-xs">
                            Course Type:
                            <span class="text-dark font-weight-bold ms-sm-2 text-uppercase">{{ course.type }}</span>
                        </span>
                        <span class="mb-2 text-xs">
                            Period:
                            <span class="text-dark ms-sm-2 font-weight-bold">{{ course.period }}</span>
                        </span>
                        <span class="text-md fw-bold text-black text-primary">
                            Fee:
                            <span class="text-dark ms-sm-2 fw-bold text-primary"><span
                                    class="text-decoration-line-through fw-bold ">Rs {{ course.fee_mp }}</span>, <span
                                    class="fw-bold text-primary"> Rs {{ course.fee_sp }} </span> </span>
                        </span>
                    </div>
                    <div class="ms-auto text-end d-flex">
                        <delete-modal :course="course" :grabCourses="grabCourses"></delete-modal>
                        <a class="btn btn-link text-dark px-3 mb-0" href="javascript:;">
                            <i class="fas fa-pencil-alt text-dark me-2" aria-hidden="true"></i>Edit
                        </a>
                    </div>
                </li>

            </ul>
        </div>
    </div>
</template>
  
<script>
import axiosConfig from '../../../baseaxios';
import DeleteModal from "./delete-modal.vue";
export default {
    name: "billing-card",
    components: {
        DeleteModal
    },
    data() {
        return {
            isloading: false,
            iserror: false,
            create_course: {
                name: "",
                info: "",
                description: "",
                validate: "",
                period: "",
                type: "",
                fee_mp: "",
                fee_sp: "",
            },
            courses: []
        }
    },
    mounted() {
        axiosConfig.get('/basic/get-courses').then(resp => {
            this.courses = resp.data;
            console.log(resp.data);
        })
    },
    methods: {
        grabCourses() {
            axiosConfig.get('/basic/get-courses').then(resp => {
                this.courses = resp.data;
                console.log(resp.data);
            })
        },
        saveCourse() {
            axiosConfig.post('/back/courses/save', this.create_course).then(resp => {
                // this.courses = resp.data;
                this.courses.push(resp.data);
                this.clearField();
                // console.log(resp.data);
            })
            // alert('asd');
        },
        clearField() {
            this.create_course = {
                name: "",
                info: "",
                description: "",
                validate: "",
                period: "",
                type: "",
                fee_mp: "",
                fee_sp: "",
            }
        }
    }
};
</script>
  